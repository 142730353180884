
import { defineComponent } from 'vue';
import { RouterLink } from 'vue-router';
import { orderBy } from 'lodash';

import Icon from '@/components/Icon/Icon.vue';
import Loader from '@/components/Loader/Loader.vue';

import AcademyHeroMessageBlock from '../../components/AcademyHeroMessageBlock/AcademyHeroMessageBlock.vue';
import AcademyArchiveCarousel from '../../components/AcademyArchiveCarousel/AcademyArchiveCarousel.vue';

import { useAcademyArchive } from '../../composables/academyArchive';
import { useAmbassador } from '../../composables/ilumaJourney';
import { useAcademyData } from '../../composables/academyData';

import { routeNames } from '@/modules/academy/router';

export default defineComponent({
  components: {
    Icon,
    AcademyHeroMessageBlock,
    AcademyArchiveCarousel,
    Loader,
    RouterLink,
  },
  setup() {
    const { archivedGroupedChallenges } = useAcademyArchive();
    const { ambassador } = useAmbassador();
    const { academyData } = useAcademyData();

    return {
      orderBy,
      archivedGroupedChallenges,
      ambassador,
      academyData,
      routeNames,
    };
  },
});
