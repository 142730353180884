import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a58c024c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "archive-carousel pb-28 pl-xxl-2" }
const _hoisted_2 = { class: "flex justify-between mb-5 items-center" }
const _hoisted_3 = {
  key: 0,
  class: "lg:flex"
}
const _hoisted_4 = ["disabled"]
const _hoisted_5 = ["disabled"]
const _hoisted_6 = { class: "archive-carousel__outer-carousel w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Badge = _resolveComponent("Badge")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_AcademyArchiveTeaser = _resolveComponent("AcademyArchiveTeaser")!
  const _directive_touch = _resolveDirective("touch")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Badge, { class: "bg-primary-soft-white text-primary-slate" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.categoryName), 1)
        ]),
        _: 1
      }),
      (_ctx.challenges.length > 3 && _ctx.shouldDisplayNavigationArrows)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("button", {
              disabled: _ctx.current <= 0,
              class: "w-12 h-12",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.prev && _ctx.prev(...args)))
            }, [
              _createVNode(_component_Icon, {
                class: _normalizeClass(["h-4", _ctx.current <= 0 ? 'opacity-50' : '']),
                theme: "light",
                type: "chevron-left"
              }, null, 8, ["class"])
            ], 8, _hoisted_4),
            _createElementVNode("button", {
              disabled: _ctx.current >= _ctx.challenges.length - 1,
              class: "w-12 h-12",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.next && _ctx.next(...args)))
            }, [
              _createVNode(_component_Icon, {
                class: _normalizeClass(["h-4", _ctx.current >= _ctx.challenges.length - 1 ? 'opacity-50' : '']),
                theme: "light",
                type: "chevron-right"
              }, null, 8, ["class"])
            ], 8, _hoisted_5)
          ]))
        : _createCommentVNode("", true)
    ]),
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_6, [
      _createElementVNode("div", {
        class: "archive-carousel__inner-carousel relative ease-out duration-200",
        style: _normalizeStyle(`
          width: ${_ctx.challenges.length * _ctx.SPACE}px;
          left: -${_ctx.current * _ctx.SPACE}px;
        `)
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.challenges, (challenge) => {
          return (_openBlock(), _createBlock(_component_AcademyArchiveTeaser, {
            key: challenge.title,
            class: "inline-block mr-5 last:mr-0",
            images: challenge.image,
            title: challenge.title,
            link: challenge.link
          }, null, 8, ["images", "title", "link"]))
        }), 128))
      ], 4)
    ])), [
      [
        _directive_touch,
        _ctx.prev,
        "swipe",
        { right: true }
      ],
      [
        _directive_touch,
        _ctx.next,
        "swipe",
        { left: true }
      ]
    ])
  ]))
}