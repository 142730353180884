import {
  DataType,
  EventsType,
  AmbassadorField,
  ChallengeSuccessScreenField,
  ChallengeField,
  IlumaOnboardingField,
  HomeTeaserField
} from './academy-types';

export const ambassadorFieldDefault: AmbassadorField = {
  event: 'guideSelected',
  guideName: '',
  guideChanged: '',
};

export const challengeSuccessScreenFieldDefault: ChallengeSuccessScreenField = {
  event: 'successScreenFlow',
  challengeName: '',
  action: '',
};

export const challengeFieldDefault: ChallengeField = {
  event: 'challengeEvent',
  challengeType: '',
  challengeName: '',
  action: '',
  progress: '',
  pointsEarned: '',
  fromArchive: '',
};

export const homeTeaserDefault: HomeTeaserField = {
  event: 'teaserClick',
  elementType: '',
  challengeName: '',
  challengeType: '',
  teaserPosition: '',
};

export const ilumaOnboardingFieldDefault: IlumaOnboardingField = {
  event: 'illumaOnboardingEvent',
  challengeName: '',
  action: '',
};

export const pushDataLayer = (event: EventsType, attrs: DataType): void => {
  const pushHandler = {
    guideSelected: {
      ...attrs.ambassador,
    },
    successScreenFlow: {
      ...attrs.challengeSuccessScreen,
    },
    challengeState: {
      ...attrs.challenge,
    },
    ilumaOnboarding: {
      ...attrs.ilumaOnboarding,
    },
    teaserClick: {
      ...attrs.homeTeaserClick,
    },
  };

  window.dataLayer.push(pushHandler[event]);
};
