import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-353e5722"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "my-xxl-4" }
const _hoisted_2 = { class: "text-fs-14-lh-20-ls-0.4 mb-s" }
const _hoisted_3 = { class: "flex flex-col items-center justify-center text-fs-16-lh-24-ls-0 px-l pb-3 pt-0 w-full" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ResponsiveImage = _resolveComponent("ResponsiveImage")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'academy-campaign-card',
      'flex',
      _ctx.short && 'academy-campaign-card--short',
      _ctx.isSpecial && 'academy-campaign-card--special',
    ])
  }, [
    _createVNode(_component_ResponsiveImage, {
      class: "absolute inset-0 bg-white",
      lightDarkOverlay: _ctx.computedTheme === 'light',
      responsiveImageSet: _ctx.image
    }, null, 8, ["lightDarkOverlay", "responsiveImageSet"]),
    _createElementVNode("div", {
      class: _normalizeClass([
        'absolute',
        'w-full h-full',
        'text-center',
        'flex flex-col items-center justify-between',
        _ctx.stretch || _ctx.short ? 'py-m px-xxl-2' : 'p-xxl-4',
        _ctx.computedTheme === 'dark'
          ? 'text-primary-slate'
          : 'text-primary-soft-white',
      ])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.category), 1),
        _createElementVNode("strong", {
          class: _normalizeClass([
            'block',
            _ctx.short ? 'text-fs-16-lh-24-ls-0.2' : 'text-fs-56-lh-64-ls--0.75',
          ])
        }, _toDisplayString(_ctx.short ? _ctx.textWithEllipsis(_ctx.title) : _ctx.title), 3)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: "text-hidden overflow-hidden",
          innerHTML: _ctx.short ? _ctx.textWithEllipsis(_ctx.description) : _ctx.description
        }, null, 8, _hoisted_4),
        (_ctx.stretch)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              href: _ctx.ctaLink,
              class: "text-fs-16-lh-24-ls-0.2 mt-7 bg-transparent",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onCardClick && _ctx.onCardClick(...args)))
            }, [
              _createElementVNode("span", {
                class: _normalizeClass([
              _ctx.computedTheme === 'dark'
                ? 'text-primary-soft-white'
                : 'text-primary-slate',
            ])
              }, _toDisplayString(_ctx.ctaText), 3),
              _createVNode(_component_Icon, {
                type: "back",
                theme: _ctx.computedTheme === 'dark' ? 'light' : 'dark',
                class: "ml-3 h-6 pointer-events-none transform rotate-180"
              }, null, 8, ["theme"])
            ], 8, _hoisted_5))
          : (!_ctx.isSpecial)
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 1,
                theme: _ctx.computedTheme === 'dark' ? 'light' : 'dark',
                fill: "",
                href: _ctx.ctaLink,
                class: "text-fs-16-lh-24-ls-0.2 self-center w-auto px-6 mt-7",
                onClick: _ctx.onCardClick
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.ctaText), 1)
                ]),
                _: 1
              }, 8, ["theme", "href", "onClick"]))
            : (_openBlock(), _createBlock(_component_Button, {
                key: 2,
                theme: _ctx.computedTheme === 'dark' ? 'light' : 'dark',
                pill: "",
                href: _ctx.ctaLink,
                fill: "",
                class: "text-fs-16-lh-24-ls-0.2 flex items-center justify-center w-auto px-6 mt-7",
                onClick: _ctx.onCardClick
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.ctaText), 1)
                ]),
                _: 1
              }, 8, ["theme", "href", "onClick"]))
      ])
    ], 2)
  ], 2))
}