
import { defineComponent, watch } from 'vue';
import { useRouter } from 'vue-router';

import Loader from '@/components/Loader/Loader.vue';
import Button from '@/components/Button/Button.vue';

import AcademyChallengeCard from '@/modules/academy/components/AcademyChallengeCard/AcademyChallengeCard.vue';
import { useAmbassador } from '@/modules/academy/composables/ilumaJourney';

export default defineComponent({
  components: {
    AcademyChallengeCard,
    Button,
    Loader,
  },
  setup() {
    const router = useRouter();

    const {
      ambassador,
      ambassadorList,
      saveAmbassador,
      selectionPageConfig,
    } = useAmbassador();

    const handleRedirect = (ambassadorValue) => {
      // Go back to the challenge that triggered
      // this page by the use of 'useAmbassador({ forceSelection: true })'

      ambassadorValue && router.push(`${router.options.history.state.back}`); // use history push not to lose challenge data
    };

    watch(ambassador, handleRedirect);

    const onSelectAmbassador = (index) => {
      saveAmbassador(ambassadorList.value[index], null);
    };

    return {
      ambassador,
      onSelectAmbassador,
      ambassadorList,
      selectionPageConfig,
    };
  },
});
