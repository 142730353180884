
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

import Button from '@/components/Button/Button.vue';
import Icon from '@/components/Icon/Icon.vue';

import { pushDataLayer } from '@/utils/GTMCustomDataLayers/academy';
import { useAmbassador } from '@/modules/academy/composables/ilumaJourney';
import AcademyChallengeCard from '@/modules/academy/components/AcademyChallengeCard/AcademyChallengeCard.vue';
import { useChallenges } from '@/modules/academy/composables/challenges';

export default defineComponent({
  name: 'AcademyChallenge',
  components: {
    Button,
    Icon,
    AcademyChallengeCard,
  },
  setup() {
    const { ambassador } = useAmbassador({ forceSelection: true });
    const { challengeFromUrl: currentChallenge } = useChallenges();
    const router = useRouter();

    const prepareAndSendGTMObject = () => {
      const { challengeContent, title, challengeDone } =
        currentChallenge.value || {};

      const challengeGTMObject = {
        event: 'challengeEvent',
        challengeType: challengeContent.type,
        challengeName: title,
        action: 'started',
        progress: '',
        pointsEarned: '',
        fromArchive: challengeDone ? 'yes' : 'no',
      };

      pushDataLayer('challengeState', {
        challenge: challengeGTMObject,
      });

      if (currentChallenge.value.isIlumaJourney) {
        const ilumaOnboardingGTMObject = {
          event: 'illumaOnboardingEvent',
          challengeName: title,
          action: 'started',
        };

        pushDataLayer('ilumaOnboarding', {
          ilumaOnboarding: ilumaOnboardingGTMObject,
        });
      }
    };

    const onStartChallenge = () => {
      setTimeout(() => router.push(currentChallenge.value.startLink), 1000);
      prepareAndSendGTMObject();
    };

    return {
      ambassador,
      currentChallenge,
      onStartChallenge,
    };
  },
});
