import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "academy-media"
}
const _hoisted_2 = { class: "mb-2 mt-10 lg:mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AudioPodcast = _resolveComponent("AudioPodcast")!
  const _component_VideoWithProgressTracking = _resolveComponent("VideoWithProgressTracking")!
  const _component_AcademyChallengeCard = _resolveComponent("AcademyChallengeCard")!

  return (_ctx.currentChallenge)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_AcademyChallengeCard, {
          isChallenge: "",
          isPlaying: "",
          isChallengeDone: _ctx.currentChallenge.challengeDone,
          title: _ctx.currentChallenge.title,
          image: _ctx.currentChallenge.image,
          type: _ctx.currentChallenge.challengeContent?.type,
          category: _ctx.currentChallenge.category,
          points: _ctx.currentChallenge.points,
          pointsType: _ctx.currentChallenge.pointsType,
          duration: _ctx.currentChallenge.challengeContent?.duration,
          class: _normalizeClass([
        `wrapper-${_ctx.currentChallenge?.challengeContent?.type}`,
        _ctx.videoLoaded && 'loaded-video',
      ]),
          onOpenCloseConfirmationModal: _ctx.pauseMedia,
          onCloseCloseConfirmationModal: _ctx.playMedia
        }, _createSlots({
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              (_ctx.currentChallenge?.challengeContent?.type === 'podcast')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    (_ctx.currentPlayingTrack)
                      ? (_openBlock(), _createBlock(_component_AudioPodcast, {
                          key: 0,
                          podcast: _ctx.currentPlayingTrack,
                          idRef: `DCE_academy_media_${_ctx.currentPlayingTrack.uid}`,
                          onAudioFragmentListened: _ctx.onMediaFragmentListened,
                          onAudioEnds: _ctx.onMediaEnds,
                          onPodcastFinished: _ctx.onMediaFinished
                        }, null, 8, ["podcast", "idRef", "onAudioFragmentListened", "onAudioEnds", "onPodcastFinished"]))
                      : _createCommentVNode("", true)
                  ], 64))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 2
        }, [
          (_ctx.currentChallenge?.challengeContent?.type === 'video')
            ? {
                name: "mediaDisplay",
                fn: _withCtx(() => [
                  (_ctx.currentPlayingTrack)
                    ? (_openBlock(), _createBlock(_component_VideoWithProgressTracking, {
                        key: _ctx.currentPlayingTrack.uid,
                        customStoryBar: "",
                        customizedVideoControlOverlay: "",
                        hideVideoControls: "",
                        video: _ctx.currentPlayingTrack,
                        idRef: `DCE_academy_media_${_ctx.currentPlayingTrack.uid}`,
                        onVideoLoaded: _ctx.onVideoLoaded,
                        onVideoFragmentListened: _ctx.onMediaFragmentListened,
                        onVideoEnds: _ctx.onMediaEnds,
                        onVideoFinished: _ctx.onMediaFinished
                      }, null, 8, ["video", "idRef", "onVideoLoaded", "onVideoFragmentListened", "onVideoEnds", "onVideoFinished"]))
                    : _createCommentVNode("", true)
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["isChallengeDone", "title", "image", "type", "category", "points", "pointsType", "duration", "class", "onOpenCloseConfirmationModal", "onCloseCloseConfirmationModal"])
      ]))
    : _createCommentVNode("", true)
}