
import { defineComponent, PropType } from 'vue';
import ResponsiveImage from '@/components/ResponsiveImage/ResponsiveImage.vue';
import { ResponsiveImageSet } from '@/types';

export default defineComponent({
  components: {
    ResponsiveImage,
  },
  props: {
    images: {
      type: Object as PropType<ResponsiveImageSet>,
    },
    title: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
  },
  setup() {
    const textWithEllipsis = (text) => {
      const length = 27;
      const shortText = text.slice(0, length);
      return text.length > length ? `${shortText}...` : text;
    };

    return {
      textWithEllipsis,
    };
  },
});
