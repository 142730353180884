import useSWRV from 'swrv';
import { ComputedRef, computed } from 'vue';

import { getLoadedStateFromSwrvList } from '@/use/useComposable'

import { fetchAcademyData } from '../api/api';

import { useAuth } from '@/use/useAuth';

const normaliseAcademyData = (data: { texts: {[key: string]: string }, user: { finished_challenges: number}}, username?: string) => {
  const replaceUsername = text => username ? text?.replace('{{USERNAME}}', username) : text;
  return {
    headlineSuccessScreenArchive: replaceUsername(data.texts['Headline_Success_Screen_Archive']),
    copySuccessScreenArchive: replaceUsername(data.texts['Copy_Success_Screen_Archive']),
    headlineWelcome: replaceUsername(data.texts['Headline_Archive_Home']),
    headlineArchiveHome: replaceUsername(data.texts['Headline_Archive_Startpage']),
    copyArchiveHome: replaceUsername(data.texts['Copy_Archive_Home']),
    headlineHomeNoAmbassador: replaceUsername(data.texts['Welcome_Headline_Start_Screen_no_Ambassador']),
    copyHomeNoAmbassador: replaceUsername(data.texts['Welcome_Copy_Start_Screen_no_Ambassador']),
    headlineEmptyHome: replaceUsername(data.texts['Empty_Home_Headline']),
    copyEmptyHome: replaceUsername(data.texts['Empty_Home_Copy']),
    hasArchiveData: data?.user?.finished_challenges
  };
}

export const useAcademyData = (): {
  academyData: ComputedRef;
  isLoaded: ComputedRef<boolean>;
} => {
  const { headers, consumer } = useAuth();

  const academyData = useSWRV('academyData', () =>
    fetchAcademyData(headers.value)
  );

  const normalisedAcademyData = computed(() =>
    academyData.data?.value
      ? normaliseAcademyData(academyData.data?.value, consumer.value?.firstName)
      : null
  );

  const isLoaded = computed(() =>
    getLoadedStateFromSwrvList([
      academyData,
    ])
  )

  return {
    academyData: normalisedAcademyData,
    isLoaded,
  };
};
