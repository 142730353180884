
import { defineComponent, PropType } from 'vue';

import { ResponsiveImageSet } from '@/types';

import Badge from '@/components/Badge/Badge.vue';
import ResponsiveImage from '@/components/ResponsiveImage/ResponsiveImage.vue';

const iconMap = {
  'playable': require('@/modules/academy/assets/svgs/challengeCategory/icons-16-challenge.svg'),
  'podcast': require('@/modules/academy/assets/svgs/challengeCategory/icons-16-audio.svg'),
  'video': require('@/modules/academy/assets/svgs/challengeCategory/icons-16-video.svg')
}

const pointsTypeMap = {
  iqos_points: 'IQOS Points',
  statuspoints: 'Statuspunkte',
};


export default defineComponent({
  components: {
    ResponsiveImage,
    Badge,
  },
  props: {
    title: { type: String, required: true },
    description: { type: String, default: '' },
    category: { type: String, default: '' },
    points: { type: Number, default: null },
    pointsType: { type: String, default: '' },
    duration: { type: Number, default: null },
    label: { type: String, default: '' },
    type: { type: String, default: '' },
    link: { type: String, required: true },
    labelClasses: { type: String, default: '' },
    image: {
      type: Object as PropType<ResponsiveImageSet>,
      default: null,
    },
    short: { type: Boolean, default: false }
  },
  emits: ['cardClick'],
  setup(props, { emit }) {
    const onCardClick = () => {
      emit('cardClick', { title: props.title, type: props.type });
    };

    return {
      iconMap,
      pointsTypeMap,
      onCardClick,
    }
  }
})
