import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-67d223dc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "archive-teaser lg:cursor-pointer" }
const _hoisted_2 = { class: "absolute bottom-0 left-0 z-10 pl-s pr-m pb-2 text-primary-soft-white" }
const _hoisted_3 = { class: "text-fs-18-lh-28-ls-0.2 leading-7" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ResponsiveImage = _resolveComponent("ResponsiveImage")!
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createBlock(_component_RouterLink, { to: _ctx.link }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ResponsiveImage, {
          class: "absolute w-full h-full z-0",
          lightDarkOverlay: "",
          responsiveImageSet: _ctx.images
        }, null, 8, ["responsiveImageSet"]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.textWithEllipsis(_ctx.title)), 1)
        ])
      ])
    ]),
    _: 1
  }, 8, ["to"]))
}