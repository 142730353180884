
import {
  defineComponent,
  ref,
  computed,
  onUnmounted,
  watch,
  reactive,
  onBeforeUnmount,
} from 'vue';
import { useStore } from 'vuex';

import useBreakpoints from '@/use/useBreakpoints';
import AcademyChallengeCard from '@/modules/academy/components/AcademyChallengeCard/AcademyChallengeCard.vue';
import AudioPodcast from '../../../../components/AudioPodcast/AudioPodcast.vue';
import VideoWithProgressTracking from '@/components/VideoWithProgressTracking/VideoWithProgressTracking.vue';
import router from '@/router';
import { routeNames } from '../../router';
import { useChallenges } from '@/modules/academy/composables/challenges';
import { useAmbassador } from '@/modules/academy/composables/ilumaJourney';
import { useAcademyConsumer } from '@/modules/academy/composables/academyConsumer';
import { pushDataLayer } from '@/utils/GTMCustomDataLayers/academy';

export default defineComponent({
  components: {
    AcademyChallengeCard,
    AudioPodcast,
    VideoWithProgressTracking,
  },
  setup() {
    const store = useStore();
    useAmbassador({ forceSelection: true }); // no direct router access if no ambassador

    const { challengeFromUrl: currentChallenge, nextChallenge } =
      useChallenges();

    const { academyConsumer } = useAcademyConsumer();

    const videoLoaded = ref(false);

    const { isLg } = reactive(useBreakpoints());
    const isScreenLg = isLg();

    const challengeDoneError = computed(
      () => store.state.academyModule.challengeDoneError
    );

    const { image, challengeContent } = currentChallenge.value || {};

    const { audioUrl, videoUrl, name, trackingAction, uid, progress } =
      challengeContent || {}; // as of now challengeContent is false instead of object when no ambassador match

    const currentPlayingTrack = reactive({
      audioUrl,
      videoUrl,
      name,
      trackingAction,
      uid,
      image,
      progress,
    });

    watch(currentChallenge, () => {
      const { audioUrl, videoUrl, name, trackingAction, uid, progress } =
        currentChallenge.value.challengeContent || {};

      if (!currentPlayingTrack.uid) {
        currentPlayingTrack.audioUrl = audioUrl;
        currentPlayingTrack.videoUrl = videoUrl;
        currentPlayingTrack.name = name;
        currentPlayingTrack.trackingAction = trackingAction;
        currentPlayingTrack.uid = uid;
        currentPlayingTrack.image = image;
        currentPlayingTrack.progress = progress;
      }
    });

    onBeforeUnmount(() => {
      const currentProgress = currentPlayingTrack.progress;
      if (!currentProgress[100]) {
        const progressKeys = Object.keys(currentProgress);

        prepareAndSendGTMObject(
          'aborted',
          progressKeys[progressKeys.length - 1] || '0',
          ''
        );
      }
    });

    onUnmounted(() => {
      // reset challenge done error
      store.dispatch('academyModule/resetChallengeDoneError');
    });

    const prepareAndSendGTMObject = (action, progress, points) => {
      const { challengeContent, title, challengeDone } =
        currentChallenge.value || {};

      const challengeGTMObject = {
        event: 'challengeEvent',
        challengeType: challengeContent.type,
        challengeName: title,
        action: action,
        progress: `${progress}%`,
        pointsEarned: points,
        fromArchive: challengeDone ? 'yes' : 'no',
      };

      pushDataLayer('challengeState', {
        challenge: challengeGTMObject,
      });

      if (action === 'completed_successful') {
        const challengeSuccessScreenGTMObject = {
          event: 'successScreenFlow',
          challengeName: currentChallenge.value.title,
          challengeType: currentChallenge.value.challengeContent.type,
          action: 'success_screen_reached',
        };

        pushDataLayer('successScreenFlow', {
          challengeSuccessScreen: challengeSuccessScreenGTMObject,
        });
      }
    };

    const onMediaEnds = async () => {
      if (currentChallenge.value.challengeDone) {
        prepareAndSendGTMObject('completed_successful', '100', '');
        router.push({
          name: routeNames.ACADEMY_ARCHIVE_CHALLENGE_SUCCESS,
        });
        return;
      }

      if (academyConsumer.value?.blockedForProgress) {
        store.dispatch('academyModule/setIsChallengePlayWithFakeUser', true);
        store.dispatch(
          'academyModule/setChallengeDone',
          currentChallenge.value
        );
        prepareAndSendGTMObject(
          'completed_successful',
          '100',
          currentChallenge.value.points
        );
        return;
      }

      // give points after 100%
      await store.dispatch(
        'academyModule/markChallengeDone',
        currentChallenge.value
      );

      if (!challengeDoneError.value) {
        prepareAndSendGTMObject(
          'completed_successful',
          '100',
          currentChallenge.value.points
        );
        router.push({
          name: routeNames[
            !nextChallenge.value || !isScreenLg
              ? 'ACADEMY_HOME'
              : 'ACADEMY_CHALLENGE_SUCCESS'
          ],
        });
        return;
      }
      prepareAndSendGTMObject('completed_not_successful', '100', '');
    };

    const onMediaFragmentListened = async ({ progress }) => {
      currentPlayingTrack.progress[String(progress)] = true;
      if (progress === 100) return; // don't call api
      await store.dispatch('academyModule/listenedToMediaFragment', {
        progress,
        challengeId: currentChallenge.value.id,
      });
    };

    const onMediaFinished = () => {
      prepareAndSendGTMObject('reached_90_percent', '90', '');
    };

    const onVideoLoaded = () => {
      videoLoaded.value = true;
    };

    const playMedia = () => {
      const mediaElement: HTMLElement = document.getElementById(
        `DCE_academy_media_${currentPlayingTrack.uid}`
      );
      (mediaElement as HTMLMediaElement).play();
    };
    const pauseMedia = () => {
      const mediaElement: HTMLElement = document.getElementById(
        `DCE_academy_media_${currentPlayingTrack.uid}`
      );
      (mediaElement as HTMLMediaElement).pause();
    };

    return {
      videoLoaded,
      currentPlayingTrack,
      currentChallenge,
      nextChallenge,
      onVideoLoaded,
      onMediaEnds,
      onMediaFragmentListened,
      onMediaFinished,
      pauseMedia,
      playMedia,
    };
  },
});
