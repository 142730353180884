
import { computed, defineComponent, PropType, reactive, ref } from 'vue';
import { RouterLink, useRoute } from 'vue-router';

import { useAmbassador } from '@/modules/academy/composables/ilumaJourney';
import { useAuth } from '@/use/useAuth';

import { ResponsiveImageSet } from '@/types';
import { ROOT_URL, routeNames } from '@/modules/academy/router';

import Icon from '@/components/Icon/Icon.vue';
import Badge from '@/components/Badge/Badge.vue';
import Button from '@/components/Button/Button.vue';
import ResponsiveImage from '@/components/ResponsiveImage/ResponsiveImage.vue';
import useBreakpoints from '@/use/useBreakpoints';

const pointsTypeMap = {
  iqos_points: 'IQOS Points',
  statuspoints: 'Statuspunkte',
};

export default defineComponent({
  components: {
    Badge,
    Button,
    Icon,
    ResponsiveImage,
    RouterLink,
  },
  props: {
    isChallengeDone: { type: Boolean, default: false },
    isChallenge: { type: Boolean, required: false },
    isPlaying: { type: Boolean, required: false },
    playable: { type: Boolean, required: false },
    title: { type: String, required: true },
    description: { type: String, default: '' },
    category: { type: String, default: '' },
    points: { type: Number, default: null },
    pointsType: { type: String, default: '' },
    duration: { type: Number, default: null },
    label: { type: String, default: '' },
    labelClasses: { type: String, default: '' },
    labelColor: { type: String, default: '' },
    type: { type: String, default: '' },
    removeImageContent: { type: Boolean, required: false },
    image: {
      type: Object as PropType<ResponsiveImageSet>,
      default: null,
    },
  },
  emits: ['openCloseConfirmationModal', 'closeCloseConfirmationModal'],
  setup(_, { emit }) {
    const route = useRoute();
    const { consumer } = useAuth();
    const { ambassador } = useAmbassador();

    const { isMd } = reactive(useBreakpoints());
    const isScreenMd = isMd();

    const isCloseConfirmationDisplayed = ref(false);

    const displayCloseConfirmationOverlay = () => {
      emit('openCloseConfirmationModal');
      isCloseConfirmationDisplayed.value = true;
    };

    const hideCloseConfirmationOverlay = () => {
      emit('closeCloseConfirmationModal');
      isCloseConfirmationDisplayed.value = false;
    };

    const isAmbassadorSelectionPage = computed(
      () => route.name === routeNames.ACADEMY_AMBASSADOR
    );

    return {
      isScreenMd,
      ambassador,
      consumer,
      displayCloseConfirmationOverlay,
      hideCloseConfirmationOverlay,
      isAmbassadorSelectionPage,
      isCloseConfirmationDisplayed,
      pointsTypeMap,
      ROOT_URL,
    };
  },
});
