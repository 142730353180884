
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  onUnmounted,
  reactive,
  ref,
} from 'vue';

import { useStore } from 'vuex';

import router from '@/router';

import Loader from '@/components/Loader/Loader.vue';

import AcademyChallengeCard from '@/modules/academy/components/AcademyChallengeCard/AcademyChallengeCard.vue';

import { useChallenges } from '@/modules/academy/composables/challenges';
import { useAcademyConsumer } from '@/modules/academy/composables/academyConsumer';
import { useAuth } from '@/use/useAuth';

import { routeNames } from '@/modules/academy/router';
import useBreakpoints from '@/use/useBreakpoints';
import { pushDataLayer } from '@/utils/GTMCustomDataLayers/academy';

export default defineComponent({
  components: {
    AcademyChallengeCard,
    Loader,
  },
  setup() {
    const store = useStore();

    const { user } = useAuth();

    const { isLg } = reactive(useBreakpoints());
    const isScreenLg = isLg();

    const isChallengeAborted = ref(true);

    const fakeLoading = ref(false);
    const shouldBlockGameCompleteAction = ref(false);
    const didTheUserWin = ref(null);

    const {
      challengeFromUrl: currentChallenge,
      nextChallenge,
      startPlayableChallenge,
    } = useChallenges();

    const { academyConsumer } = useAcademyConsumer();

    const prepareAndSendGTMObject = (action, points) => {
      const { challengeContent, title, challengeDone } =
        currentChallenge.value || {};

      const challengeGTMObject = {
        event: 'challengeEvent',
        challengeType: challengeContent.type,
        challengeName: title,
        action: action,
        progress: '',
        pointsEarned: points,
        fromArchive: challengeDone ? 'yes' : 'no',
      };

      pushDataLayer('challengeState', {
        challenge: challengeGTMObject,
      });

      if (action === 'completed_successful') {
        if (currentChallenge.value.isIlumaJourney) {
          const ilumaOnboardingGTMObject = {
            event: 'illumaOnboardingEvent',
            challengeName: title,
            action: 'completed',
          };

          pushDataLayer('ilumaOnboarding', {
            ilumaOnboarding: ilumaOnboardingGTMObject,
          });
        }

        const challengeSuccessScreenGTMObject = {
          event: 'successScreenFlow',
          challengeName: currentChallenge.value.title,
          challengeType: currentChallenge.value.challengeContent.type,
          action: 'success_screen_reached',
        };

        pushDataLayer('successScreenFlow', {
          challengeSuccessScreen: challengeSuccessScreenGTMObject,
        });
      }
    };

    onUnmounted(() => {
      // reset challenge done error
      store.dispatch('academyModule/resetChallengeDoneError');
      window.removeEventListener('message', handleGameEvents);
      store.dispatch('academyModule/setIsChallengePlayWithFakeUser', null);
    });

    onBeforeUnmount(() => {
      if (isChallengeAborted.value) {
        prepareAndSendGTMObject('aborted', '');
      }
    });

    const handleSuccess = () => {
      fakeLoading.value = true;

      if (currentChallenge.value.challengeDone) {
        prepareAndSendGTMObject('completed_successful', '');
        router.push({
          name: routeNames.ACADEMY_ARCHIVE_CHALLENGE_SUCCESS,
        });
        return;
      }

      onGameFinished();

      prepareAndSendGTMObject(
        'completed_successful',
        currentChallenge.value.points
      );

      if (academyConsumer.value.blockedForProgress) {
        fakeLoading.value = false;
        return;
      }
      // Delay redirection to give time for Playable to do the BE to BE api call
      setTimeout(() => {
        router.push({
          name: routeNames[
            !nextChallenge.value || !isScreenLg
              ? 'ACADEMY_HOME'
              : 'ACADEMY_CHALLENGE_SUCCESS'
          ],
        });
        fakeLoading.value = false;
      }, 5000);
    };

    const handleFailure = () => {
      prepareAndSendGTMObject('completed_not_successful', '');
      router.push({ name: routeNames['ACADEMY_CHALLENGE_FAILURE'] });
    };

    const handleGameEvents = ({ data: eventData, origin }) => {
      const allowedOrigins = /https:\/\/.*\.(leadfamly|playable)\.com$/;
      const allowedTypes = ['leadfamly-campaign'];
      const baseActionsMap = {
        'campaign-ready': () => null,
        'game-state': (data) => {
          // Save win status for initiate-takeover action later
          didTheUserWin.value = data?.winner;
          if (!shouldBlockGameCompleteAction.value) {
            const action = data?.winner ? handleSuccess : handleFailure;
            isChallengeAborted.value = false;
            action();
          }
        },
      };
      const customActionsMap = {
        'block-default': () => {
          shouldBlockGameCompleteAction.value = true;
        },
        'initiate-takeover': () => {
          const action = didTheUserWin.value ? handleSuccess : handleFailure;
          isChallengeAborted.value = false;
          action();
        },
      };

      const actionsMap = {
        ...baseActionsMap,
        ...customActionsMap,
      };

      if (allowedOrigins.test(origin)) {
        const { data, type, action } = JSON.parse(eventData);
        const isCustomAction = Object.keys(customActionsMap).includes(action);
        if (allowedTypes.includes(type) || isCustomAction) {
          const handler = actionsMap[action];
          handler?.call(this, data);
        }
      }
    };

    onMounted(() => {
      window.removeEventListener('message', handleGameEvents);
      window.addEventListener('message', handleGameEvents);
      startPlayableChallenge(currentChallenge.value);
    });

    const computedGameUrl = computed(() => {
      const queryParams = `person_id=${user.value.uid}&token=${user.value.jwt}`;
      return user.value
        ? `${currentChallenge.value.challengeContent.gameUrl}?iframe=dontbreakout&${queryParams}`
        : null;
    });

    const onGameFinished = () => {
      store.dispatch('academyModule/setChallengeDone', currentChallenge.value);
    };

    return {
      fakeLoading,
      isScreenLg,
      currentChallenge,
      computedGameUrl,
    };
  },
});
