import { computed, Ref, ComputedRef } from 'vue';
import { useRouter } from 'vue-router';
import useSWRV from 'swrv';

import { ResponsiveImageSet } from '@/types';

import { getLoadedStateFromSwrvList } from '@/use/useComposable'

import { useAuth } from '@/use/useAuth';

import {
  fetchAmbassadors,
  fetchConsumer,
  fetchGroups,
  postAmbassador,
  postRedeemIlumaReward,
} from '../api/api';

import {
  Ambassador,
  AmbassadorDTO,
  Group,
} from '@/modules/academy/types';
import { routeNames } from '@/modules/academy/router';

import { normaliseCmsImage } from '@/components/ResponsiveImage/utils';
import { pushDataLayer } from '@/utils/GTMCustomDataLayers/academy';

export const normaliseAmbassadors = (
  ambassadorsDTO: AmbassadorDTO[],
  username: string,
): Ambassador[] => {
  const replaceUsername = text => username ? text?.replace('{{USERNAME}}', username) : text;
  return ambassadorsDTO.map((item) => ({
    id: item.id,
    name: item.name,
    title: item.title,
    image: normaliseCmsImage({
      alt: item.title,
      image: item.image,
    }),
    headlineSuccessScreenArchive: replaceUsername(item.headline_success_screen_archive),
    copySuccessScreenArchive: replaceUsername(item.copy_success_screen_archive),
    headlineWelcome: replaceUsername(item.headline_welcome),
    headlineArchiveHome: replaceUsername(item.headline_archive_home),
    copyArchiveHome: replaceUsername(item.copy_archive_home),
  }))
}

const prepareAndSendGTMObject = (ambassador: Ambassador, isChanged) => {
  const { name } = ambassador || {};

  const ambassadorGTMObject = {
    event: 'guideSelected',
    guideName: name,
    guideChanged: isChanged ? 'yes': 'no',
  };

  pushDataLayer('guideSelected', { ambassador: ambassadorGTMObject });
};

type UseAmbassadorReturnType = {
  ambassador: ComputedRef<Ambassador>,
  otherAmbassador: ComputedRef<Ambassador>,
  ambassadorList: ComputedRef<Ambassador[]>,
  isLoading: Ref<boolean>,
  error: Ref<string>,
  saveAmbassador: (ambassador: Ambassador, isChanged: boolean | null) => void,
  shouldDisplayIlumaTeaser: (Group) => boolean,
  selectionPageConfig: ComputedRef<{ headline: string, text: string, image: ResponsiveImageSet}>,
  isLoaded: ComputedRef<boolean>
}

export const useAmbassador = (
  { forceSelection }: { forceSelection: boolean } = { forceSelection: false }
): UseAmbassadorReturnType => {
  const router = useRouter()
  const { headers, consumer: authConsumer } = useAuth();

  const ambassadorContent = useSWRV(
    'ambassadors',
    () => fetchAmbassadors(headers.value)
  );

  const consumerContent = useSWRV('consumer', () => fetchConsumer(headers.value));

  const ambassadorList = computed(() => normaliseAmbassadors(ambassadorContent.data.value?.ambassadors || [], authConsumer.value.firstName))

  const ambassador = computed(() => ambassadorList.value.find(
    (ambassador) => ambassador.id === consumerContent.data.value.ambassador_id,
  ))

  const otherAmbassador = computed(() => ambassadorList.value.find(
    (ambassador) => ambassador.id !== consumerContent.data.value.ambassador_id,
  ))

  const saveAmbassador = (ambassador: Ambassador, isChanged) => {

    prepareAndSendGTMObject(ambassador, isChanged);

    return postAmbassador(ambassador.id)(headers.value)
      .then(() => consumerContent.mutate())
  }

  if(!ambassador.value && forceSelection) {
    router.push({ name: routeNames.ACADEMY_AMBASSADOR })
  }

  const shouldDisplayIlumaTeaser = (_group?: Group) => {
    if(!_group) return false;

    const group = consumerContent.data?.value?.groups
      ?.find((item) => item.group_id === _group.id)
    return group?.group_done && !group?.points_redeemed
  }

  const selectionPageConfig = computed(() => ({
    headline: ambassadorContent.data.value?.selector_data?.headline,
    text: ambassadorContent.data.value?.selector_data?.text,
    image: normaliseCmsImage(ambassadorContent.data.value?.selector_data || {}),
  }))

  const isLoaded = computed(() =>
    getLoadedStateFromSwrvList([
      ambassadorContent,
      consumerContent,
    ])
  )

  return {
    ambassador,
    ambassadorList,
    error: ambassadorContent.error,
    isLoading: ambassadorContent.isValidating,
    otherAmbassador,
    saveAmbassador,
    selectionPageConfig,
    shouldDisplayIlumaTeaser,
    isLoaded,
  }
}

export const useIlumaRewards = (): {
  redeemIlumaReward: (reward: 'points' | 'gift') => Promise<void>,
} => {
  const { headers } = useAuth();
  const { data: groups } = useSWRV('groups', () => fetchGroups(headers.value));

  const ilumaGroup = computed(() =>
    (groups.value || []).find(group => group.title === 'Iluma Journey')
  )
  const redeemIlumaReward = (reward: 'points' | 'gift') =>
    postRedeemIlumaReward(ilumaGroup.value.id, reward)(headers.value)

  return {
    redeemIlumaReward,
  }
}