
import { defineComponent, PropType } from 'vue';

import ResponsiveImage from '@/components/ResponsiveImage/ResponsiveImage.vue';

import { ResponsiveImageSet } from '@/types';

const pointsTypeMap = {
  iqos_points: 'IQOS Points',
  statuspoints: 'Statuspunkte',
};

export default defineComponent({
  components: {
    ResponsiveImage,
  },
  props: {
    image: {
      type: Object as PropType<ResponsiveImageSet | null>,
      required: false,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    customReward: {
      type: String,
      default: null,
    },
    points: {
      type: Number,
      default: null,
    },
    pointsType: {
      type: String,
      default: null,
    },
    isPointsOnBottom: {
      type: Boolean,
      default: false,
    },
    contentCentered: {
      type: Boolean,
      default: false,
    },
    background: {
      type: String,
      default: '',
    },
    textLight: {
      type: Boolean,
      default: false,
    },
    noPaddingBottom:{
      type: Boolean,
      default: false,
    }
  },
  setup() {
    return { pointsTypeMap };
  },
});
