import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b72a4ee0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "absolute bottom-0 left-0 w-full text-primary-soft-white" }
const _hoisted_2 = { class: "px-l mb-xxs" }
const _hoisted_3 = {
  key: 0,
  class: "text-fs-14-lh-20-ls-0.4 mb-xxs flex items-center"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "block text-fs-28-lh-40-ls-0" }
const _hoisted_6 = { class: "opacity-60 text-fs-12-lh-20-ls-0.2 px-l pb-3 pt-0 w-full flex justify-between" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ResponsiveImage = _resolveComponent("ResponsiveImage")!
  const _component_Badge = _resolveComponent("Badge")!
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createBlock(_component_RouterLink, {
    to: _ctx.link,
    class: _normalizeClass([
      'academy-card',
      _ctx.short && 'academy-card--short',
    ]),
    onClick: _cache[0] || (_cache[0] = () => _ctx.onCardClick())
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ResponsiveImage, {
        class: "absolute inset-0 bg-white",
        overlayOpacityValue: 58,
        responsiveImageSet: _ctx.image
      }, null, 8, ["responsiveImageSet"]),
      (_ctx.label)
        ? (_openBlock(), _createBlock(_component_Badge, {
            key: 0,
            class: _normalizeClass(['academy-card__label', _ctx.labelClasses])
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.label), 1)
            ]),
            _: 1
          }, 8, ["class"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.category)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                _createElementVNode("img", {
                  src: _ctx.iconMap[_ctx.type],
                  class: "mr-xs"
                }, null, 8, _hoisted_4),
                _createTextVNode(" " + _toDisplayString(_ctx.category), 1)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("strong", _hoisted_5, _toDisplayString(_ctx.title), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          (_ctx.points)
            ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.points) + " " + _toDisplayString(_ctx.pointsTypeMap[_ctx.pointsType]), 1))
            : _createCommentVNode("", true),
          (_ctx.duration)
            ? (_openBlock(), _createElementBlock("span", _hoisted_8, " Dauer: " + _toDisplayString(_ctx.duration) + " Minuten ", 1))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }, 8, ["to", "class"]))
}