
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  PropType,
  ref,
} from 'vue';
import throttle from 'lodash/throttle';

import AcademyArchiveTeaser from '@/modules/academy/components/AcademyArchiveTeaser/AcademyArchiveTeaser.vue';

import Icon from '@/components/Icon/Icon.vue';
import Badge from '@/components/Badge/Badge.vue';

import { Challenge } from '@/modules/academy/types'


export default defineComponent({
  components: {
    Badge,
    AcademyArchiveTeaser,
    Icon,
  },
  props: {
    categoryName: {
      type: String,
      required: true,
    },
    challenges: {
      type: Object as PropType<Challenge[]>,
      required: true,
    },
  },
  setup(props) {
    const SPACE = 195 + 20; // ArchiveTeaser width + margin right
    const current = ref(0);

    const windowWidth = ref(window.innerWidth)
    const throttledResizeHandler = throttle(
      () => { windowWidth.value = window.innerWidth },
      300,
    )

    onMounted(() => {
      window.addEventListener('resize', throttledResizeHandler)
    })

    onUnmounted(() => {
      window.removeEventListener('resize', throttledResizeHandler)
    })


    const next = () => {
      current.value =
        current.value <= props.challenges.length - 2
          ? current.value + 1
          : current.value;
    };
    const prev = () => {
      current.value = current.value > 0 ? current.value - 1 : current.value;
    };

    const shouldDisplayNavigationArrows = computed(() => {
      const leftOffset = 550 // Offset of lateral panel + carousel left spacings
      const carouselWidth = props.challenges.length * SPACE
      return windowWidth.value < (leftOffset + carouselWidth)
    })

    return {
      current,
      next,
      prev,
      SPACE,
      shouldDisplayNavigationArrows,
    };
  },
});
