
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

import Accordion from '@/components/Accordion/Accordion.vue';
import Icon from '@/components/Icon/Icon.vue';

import { ROOT_URL } from '@/modules/academy/router';

import { ACADEMY_FAQs, REWARDSHOP_FAQs } from './rework-faqs';

export default defineComponent({
  components: {
    Accordion,
    Icon,
  },
  setup() {
    const router = useRouter();

    return {
      window,
      router,
      ACADEMY_FAQs,
      REWARDSHOP_FAQs,
      ROOT_URL,
    };
  },
});
