
import { defineComponent, PropType, computed } from 'vue';

import { ResponsiveImageSet } from '@/types';
import ResponsiveImage from '@/components/ResponsiveImage/ResponsiveImage.vue';
import Icon from '@/components/Icon/Icon.vue';
import Button from '@/components/Button/Button.vue';

export default defineComponent({
  components: {
    ResponsiveImage,
    Icon,
    Button,
  },
  props: {
    title: { type: String, required: false },
    description: { type: String, default: '' },
    category: { type: String, default: '' },
    link: { type: String, required: false },
    isSpecial: { type: Boolean, default: false },
    image: {
      type: Object as PropType<ResponsiveImageSet>,
      default: null,
    },
    short: { type: Boolean, default: false },
    stretch: { type: Boolean, default: false },
    theme: { type: String, default: 'dark' },
    ctaText: { type: String, default: '' },
    ctaLink: { type: String, default: '' },
  },
  emits: ['cardClick'],
  setup(props, { emit }) {
    const onCardClick = () => {
      emit('cardClick');
    };
    const textWithEllipsis = (text) => {
      const length = 27;
      const shortText = text.slice(0, length);
      return text.length > length ? `${shortText}...` : text;
    };

    const computedTheme = computed(() =>
      ['light', 'dark'].includes(props.theme)
        ? props.theme
        : 'light'
    )

    return {
      textWithEllipsis,
      computedTheme,
      onCardClick,
    };
  },
});
