
import { computed, defineComponent, ref } from 'vue';
import { RouterLink, useRoute } from 'vue-router';
import { useStore } from 'vuex';

import Badge from '@/components/Badge/Badge.vue';
import Button from '@/components/Button/Button.vue';
import Icon from '@/components/Icon/Icon.vue';
import ResponsiveImage from '@/components/ResponsiveImage/ResponsiveImage.vue';

import { pushDataLayer } from '@/utils/GTMCustomDataLayers/academy';

import AcademyHeroMessageBlock from '@/modules/academy/components/AcademyHeroMessageBlock/AcademyHeroMessageBlock.vue';
import AcademyHeroTeaser from '@/modules/academy/components/AcademyHeroTeaser/AcademyHeroTeaser.vue';

import { useChallenges } from '@/modules/academy/composables/challenges';
import { useAmbassador } from '@/modules/academy/composables/ilumaJourney';
import { useAcademyData } from '@/modules/academy/composables/academyData';
import { useHomepage } from '@/modules/academy/composables/homepage';

import { routeNames, ROOT_URL } from '@/modules/academy/router';

const pointsTypeMap = {
  iqos_points: 'IQOS Points',
  statuspoints: 'Statuspunkte',
};

export default defineComponent({
  components: {
    ResponsiveImage,
    Button,
    Icon,
    AcademyHeroMessageBlock,
    Badge,
    AcademyHeroTeaser,
    RouterLink,
  },
  setup() {
    const store = useStore();
    const { name: routeName, params, query } = useRoute();

    const isFromArchivePage =
      routeName === routeNames.ACADEMY_ARCHIVE_CHALLENGE_SUCCESS;

    const {
      challengeFromUrl,
      fakeIlumaChallenge,
      nextChallenge: upcomingChallenge,
      FAKE_ILUMA_CHALLENGE_ID,
    } = useChallenges();

    const { firstChallenge, ilumaGroup, campaigns } = useHomepage();

    const { ambassador, otherAmbassador, saveAmbassador } = useAmbassador();
    const { academyData } = useAcademyData();

    const isAnimation = ref(false);

    const campaign = computed(() => campaigns.value[0]);

    const isIlumaFakeChallenge = computed(
      () => params.challenge === 'iluma-group-gift'
    );

    const currentChallenge = computed(() =>
      isIlumaFakeChallenge.value ? fakeIlumaChallenge : challengeFromUrl.value
    );

    const nextChallenge = computed(() => {
      const updatedFakeIlumaChallenge = {
        ...fakeIlumaChallenge,
        image: {
          ...fakeIlumaChallenge.image,
          // Use specific images here because of centering and formatting
          desktop: {
            x1: require('@/assets/academy/iluma-reward-teaser-tile-teaser@1x.jpg'),
            x2: require('@/assets/academy/iluma-reward-teaser-tile-teaser@2x.jpg'),
          },
        },
      };
      const challengeBelongsToIlumaGroup = (ilumaGroup.value?.challenges || [])
        .map(({ id }) => id)
        .includes(currentChallenge.value.id);

      const {
        id,
        bigHeadline,
        smallHeadline,
        text,
        label,
        labelColor,
        link,
        image,
      } = campaign.value;

      const campaignFakeChallenge = {
        id,
        title: bigHeadline,
        category: smallHeadline,
        description: text,
        points: null,
        pointsType: null,
        label,
        labelColor,
        image,
        challengeContent: {
          ctaName: link.text,
          type: null,
          duration: null,
          welcomeHeadline: (ambassador || academyData).value
            .headlineSuccessScreenArchive,
          welcomeMessage: (ambassador || academyData).value
            .copySuccessScreenArchive,
        },
        link: link.href,
        startLink: link.href,
      };

      return !isFromArchivePage
        ? ilumaGroup.value?.group_done && challengeBelongsToIlumaGroup
          ? updatedFakeIlumaChallenge
          : isIlumaFakeChallenge.value
          ? firstChallenge.value
          : !upcomingChallenge.value ||
            upcomingChallenge?.value?.id === currentChallenge?.value?.id
          ? campaignFakeChallenge
          : upcomingChallenge.value
        : campaignFakeChallenge;
    });

    const prepareAndSendGTMObject = (isClickAfterAnimation: boolean) => {
      if (isClickAfterAnimation) {
        const challengeGTMObject = {
          event: 'challengeEvent',
          challengeType:
            nextChallenge.value.challengeContent.type || 'external_link',
          challengeName: nextChallenge.value.title,
          action: 'started',
          progress: '',
          pointsEarned: '',
          fromArchive: isFromArchivePage ? 'yes' : 'no',
        };

        pushDataLayer('challengeState', {
          challenge: challengeGTMObject,
        });

        if (nextChallenge.value.isIlumaJourney) {
          const ilumaOnboardingGTMObject = {
            event: 'illumaOnboardingEvent',
            challengeName: nextChallenge.value.title,
            action: 'started',
          };

          pushDataLayer('ilumaOnboarding', {
            ilumaOnboarding: ilumaOnboardingGTMObject,
          });
        }
        return;
      }

      const challengeSuccessScreenGTMObject = {
        event: 'successScreenFlow',
        challengeName: currentChallenge.value.title,
        challengeType: currentChallenge.value.challengeContent.type,
        action: 'recommendation_followed',
      };

      pushDataLayer('successScreenFlow', {
        challengeSuccessScreen: challengeSuccessScreenGTMObject,
      });
    };

    const startAnimation = () => {
      isAnimation.value = true;
      prepareAndSendGTMObject(false);
    };

    const onNextChallengeClick = () => {
      if (isAnimation.value) return;

      prepareAndSendGTMObject(isAnimation.value);
    };

    const onSwitchAmbassador = () => {
      saveAmbassador(otherAmbassador.value, true);
    };

    const customReward = computed(() =>
      query.gift === 'product' ? 'Deine gratis TEREA Packung*' : null
    );

    const isIlumaCompletedWithProduct = computed(() =>
      ['product'].includes(query.gift as string)
    );

    return {
      customReward,
      isIlumaCompletedWithProduct,
      isFromArchivePage,
      campaign,
      pointsTypeMap,
      academyData,
      ambassador,
      otherAmbassador,
      currentChallenge,
      nextChallenge,
      isAnimation,
      startAnimation,
      onSwitchAmbassador,
      onNextChallengeClick,
      prepareAndSendGTMObject,
      upcomingChallenge,
      isIlumaFakeChallenge,
      FAKE_ILUMA_CHALLENGE_ID,
      consumer: store.state.consumerModule.consumer,
      ROOT_URL,
    };
  },
});
