
import { defineComponent } from 'vue';

import Button from '@/components/Button/Button.vue';
import ResponsiveImage from '@/components/ResponsiveImage/ResponsiveImage.vue';

export default defineComponent({
  components: {
    Button,
    ResponsiveImage
  },
  props: {
    isSpecial: { type: Boolean, default: false },
    short: { type: Boolean, default: false },
    stretch: { type: Boolean, default: false },
    remainingChallenges: { type: Number, required: true },
    vertical: { type: Boolean, default: false },
    ctaLink: { type: String, default: null },
    squared: { type: Boolean, default: false },
  },
  emits: ['ctaClick'],
  setup(_, { emit }) {
    const onCtaClick = () => {
      emit('ctaClick');
    };
    const textWithEllipsis = (text) => {
      const length = 27;
      const shortText = text.slice(0, length);
      return text.length > length ? `${shortText}...` : text;
    };

    return {
      textWithEllipsis,
      title: 'Hol\' Dir Deine ILUMA Special Prämie.',
      description: 'Beende das ILUMA Special und erhalte eine gratis Packung TEREA.*',
      ctaText: 'Jetzt TEREA sichern',
      onCtaClick,
    };
  },
});
