import useSWRV from 'swrv';
import { ComputedRef, computed } from 'vue';
import { fetchArchivedChallenges } from '../api/api';

import { useAuth } from '@/use/useAuth';
import { merge } from 'lodash';
import { normaliseChallenge } from './challenges';
import { Challenge } from '../types';

export const useAcademyArchive = (): {
  archivedGroupedChallenges: ComputedRef<Challenge[]>;
} => {
  const { headers, consumer } = useAuth();

  const archivedChallenges = useSWRV('archivedChallenges', () =>
    fetchArchivedChallenges(headers.value)
  );

  const archivedGroupedChallenges = computed(() =>
    archivedChallenges.data.value
      ? archivedChallenges.data.value.reduce((acc, challenge) => {
          const categoryList = acc[challenge.group.title] || [];
          return merge(acc, {
            [challenge.group.title]: [
              ...categoryList,
              normaliseChallenge(challenge, consumer.value?.firstName),
            ],
          });
        }, {})
      : {}
  );

  return {
    archivedGroupedChallenges,
  };
};
