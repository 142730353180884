
import { defineComponent, ref, reactive } from 'vue';
import { useStore } from 'vuex';

import Button from '@/components/Button/Button.vue';
import Icon from '@/components/Icon/Icon.vue';

import useBreakpoints from '@/use/useBreakpoints';

import AcademyHeroMessageBlock from '@/modules/academy/components/AcademyHeroMessageBlock/AcademyHeroMessageBlock.vue';
import { useChallenges } from '@/modules/academy/composables/challenges';
import {
  useAmbassador,
  useIlumaRewards,
} from '@/modules/academy/composables/ilumaJourney';

import router from '@/router';
import { routeNames } from '@/modules/academy/router';
import { pushDataLayer } from '@/utils/GTMCustomDataLayers/academy';

const REDEEM_REWARD_OPTIONS = {
  POINTS: 'points',
  PRODUCT: 'product'
}

export default defineComponent({
  components: {
    Icon,
    AcademyHeroMessageBlock,
    Button,
  },
  setup() {
    const store = useStore();
    const { ambassador } = useAmbassador();
    const { redeemIlumaReward } = useIlumaRewards();
    const { fakeIlumaChallenge } = useChallenges();

    let userName = '';
    let selectedOption = ref(null);

    if (
      store.state.consumerModule &&
      store.state.consumerModule.consumer &&
      store.state.consumerModule.consumer.firstName
    ) {
      userName = store.state.consumerModule.consumer.firstName;
    }
    const onClickOption = (key) => {
      selectedOption.value = key;
    };

    const prepareAndSendGTMObject = (reward) => {
      const ilumaOnboardingGTMObject = {
        event: 'illumaOnboardingEvent',
        challengeName: '',
        action: reward === REDEEM_REWARD_OPTIONS.PRODUCT ? 'pack_selected': 'points_selected',
      };

      pushDataLayer('ilumaOnboarding', {
        ilumaOnboarding: ilumaOnboardingGTMObject,
      });
    };

    const { isLg } = reactive(useBreakpoints());
    const isScreenLg = isLg();

    const redeemError = ref('');
    const isLoading = ref(false);

    const redeemReward = async (reward) => {
      try {
        isLoading.value = true;
        redeemError.value = '';
        await redeemIlumaReward(reward);

        store.dispatch(
          'academyModule/setChallengeDone',
          fakeIlumaChallenge,
        );

        prepareAndSendGTMObject(reward);

        router.push({
          name: routeNames[
            !isScreenLg ? 'ACADEMY_HOME' : 'ACADEMY_CHALLENGE_SUCCESS'
          ],
          params: {
            challenge: 'iluma-group-gift',
          },
          query: {
            gift: reward,
          }
        });
      } catch (err) {
        redeemError.value =
          'Es ist ein Fehler aufgetreten. Bitte versuche es erneut.';
      }
      isLoading.value = false;
    };

    return {
      REDEEM_REWARD_OPTIONS,
      ambassador,
      isLoading,
      onClickOption,
      redeemError,
      redeemReward,
      routeNames,
      selectedOption,
      userName,
    };
  },
});
