
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';

import Button from '@/components/Button/Button.vue';
import Icon from '@/components/Icon/Icon.vue';

import { useAmbassador } from '@/modules/academy/composables/ilumaJourney';
import AcademyChallengeCard from '@/modules/academy/components/AcademyChallengeCard/AcademyChallengeCard.vue';
import { useChallenges } from '@/modules/academy/composables/challenges';
import { useAuth } from '@/use/useAuth';

import ResponsiveImage from '@/components/ResponsiveImage/ResponsiveImage.vue';
import { pushDataLayer } from '@/utils/GTMCustomDataLayers/academy';

import { routeNames } from '../../router';

const CHALLENGE_BUTTON_TEXT_MAP = {
  video: 'Play Video',
  podcast: 'Play Podcast',
  playable: 'Start',
};

export default defineComponent({
  name: 'AcademyChallengeFailure',
  components: {
    Button,
    Icon,
    ResponsiveImage,
    AcademyChallengeCard,
  },
  setup() {
    const router = useRouter();
    const { ambassador } = useAmbassador({ forceSelection: true });
    const { challengeFromUrl: currentChallenge } = useChallenges();
    const { consumer } = useAuth();

    const isBackToChallengeHover = ref(false);

    const prepareAndSendGTMObject = () => {
      const challengeGTMObject = {
        event: 'challengeEvent',
        challengeType: currentChallenge.value.challengeContent.type,
        challengeName: currentChallenge.value.title,
        action: 'start_again',
        progress: '',
        pointsEarned: '',
        fromArchive: 'no',
      };

      pushDataLayer('challengeState', {
        challenge: challengeGTMObject,
      });

      if (currentChallenge.value.isIlumaJourney) {
        const ilumaOnboardingGTMObject = {
          event: 'illumaOnboardingEvent',
          challengeName: currentChallenge.value.title,
          action: 'start_again',
        };

        pushDataLayer('ilumaOnboarding', {
          ilumaOnboarding: ilumaOnboardingGTMObject,
        });
      }
    };

    const backToChallenge = () => {
      prepareAndSendGTMObject();
      router.push({
        name: routeNames.ACADEMY_CHALLENGE_PLAYABLE_PLAY,
        params: { challenge: currentChallenge.value.slug },
      });
    };

    return {
      CHALLENGE_BUTTON_TEXT_MAP,
      ambassador,
      currentChallenge,
      isBackToChallengeHover,
      consumer,
      backToChallenge,
    };
  },
});
