export const ACADEMY_FAQs = [
  {
    question: 'Was ist die IQOS Club Academy?',
    answer: `Die IQOS Club Academy ist unsere Plattform, die Dich zum IQOS Experten macht. In verschiedenen Challenges, Videos oder Podcast erfährst Du mehr aus der Welt von IQOS.
    <br/>
    Ebenso hast Du die Möglichkeit, Statuspunkte und IQOS Points zu sammeln.
    <br/>
    In der Academy warten regelmäßig neue Inhalte und Themen auf Dich: Vorbeischauen lohnt sich!`,
  },
  {
    question: 'Was sind Challenges?',
    answer: `In den IQOS Club Challenges erwarten Dich interessante Inhalte aus verschiedenen Themengebieten und Du lernst dabei spielerisch neues über IQOS.
    <br/>
    Nach erfolgreichem Bestehen einer Challenges erhältst Du einmalig 10 IQOS Points und 10 Statuspunkte gutgeschrieben. Die Gutschrift erfolgt automatisch auf Dein IQOS Club Punktekonto.`,
  },
  {
    question: 'Wie kann ich in der IQOS Club Academy Punkte sammeln? ',
    answer: `In der IQOS Club Academy kannst Du schnell und einfach Punkte sammeln, so erhältst Du zum Beispiel:
    <br/>
      - 10 IQOS Points und 10 Statuspunkte für das erfolgreiche Bestehen von Challenges
    <br/>
      - 50 Statuspunkte für das Anhöhen von Podcasts (Achtung: Schummeln gilt nicht! Nur wenn Du den Podcast komplett anhörst, erhältst Du auch die Statuspunkte) 
    <br/>
      - 30 Statuspunkte für das Anschauen von Videos. 
    <br/>
    <br/>
      Je Challenge, Video oder Podcast werden Dir die Punkte einmalig und automatisch auf Dein IQOS Club Punktekonto gutschrieben.`,
  },
  {
    question: 'Wo kann ich meine bereits abgeschlossenen Inhalte finden?',
    answer: `Bereits erfolgreich abgeschlossene Challenges, Videos oder Podcasts findest Du in Deinem Archiv. Auf dieses kannst Du jederzeit nochmals zugreifen und Dein Wissen auffrischen.
    <br/>
    Hier werden Dir aber nicht nochmals die Punkte gutgeschrieben.`,
  },
];

export const REWARDSHOP_FAQs = [
  {
    question:
      'Was sind Statuspunkte und was sind IQOS Points? \n Weshalb unterscheiden sich diese?',
    answer: `
      Die Höhe der gesammelten Statuspunkte definieren Deine Vorteile im IQOS Club: eine Übersicht findest Du unter "Vorteile erleben". Statuspunkte sammelst Du mit jeder Aktivität. Die Höhe der Statuspunkte variiert je nach Aktivität.
      <br/>
    Zusätzlich kannst du IQOS Points sammeln, welche Du gegen attraktive Prämien oder IQOS Produkte eintauschen kannst. IQOS Points erhältst Du bei erfolgreicher Empfehlung eines Freundes oder durch den Abschluss einer Challenge in der IQOS Club Academy.
    <br/>
    Für jeden gesammelten IQOS Points erhältst Du auch in gleicher Anzahl Statuspunkte.
    <br/>
    Wenn Du IQOS Points gegen Prämien einlöst, verringert sich Deine Anzahl an IQOS Points, jedoch hat dies keine Auswirkung auf Deine derzeitigen Statuspunkte und Vorteile im Club.
    <br/>
    Daher unterscheiden sich auch beide Punktestände.`,
  },
  {
    question: 'Wie kann ich IQOS Points sammeln?',
    answer: `IQOS Points können über verschiedenen Aktivitäten gesammelt werden: eine Übersicht findest Du auf der Seite "Punkte sammeln".
    <br/>
    <br/>
    Bitte beachte, dass IQOS Points nicht gleichzusetzen sind mit Statuspunkten. Mehr dazu findest Du hier in den FAQs.`,
  },
  {
    question:
      'Was kann ich mit IQOS Points machen und wie viel sind diese wert?',
    answer: `IQOS Points können im Prämienshop gegen attraktive Prämien unserer Partner eingetauscht werden. Zusätzlich kannst Du Deine IQOS Points für IQOS Geräte und Accessoires (ausgenommen Tabaksticks) in unserem Onlineshop auf iqos.com einlösen. In unserem Onlineshop auf iqos.com einfach das gewünschte IQOS Gerät oder Zubehör in den Warenkorb legen und im Schritt Zahlung die Höhe der einzulösenden Punkte eingeben.
    <br/>
    10 IQOS Points entsprechen 1 EUR.    
    <br/>                                                            
    Achte darauf, dass Du bei Lieferung Deiner Sendung zuhause bist, da für den Empfang eine Ausweisprüfung notwendig ist.
    <br/>
    Bitte beachte, dass das Einlösen Deiner IQOS Points gegen Tabaksticks ausgenommen ist.`,
  },
  {
    question:
      'Kann ich auch eine Prämie bestellen, für die meine IQOS Points nicht ausreichen?',
    answer: `Du kannst IQOS Geräte und Zubehör (Tabaksticks ausgenommen) über iqos.com bestellen, auch wenn Du nicht genügend IQOS Points zur Verfügung hast oder nur einen Teil Deiner Punkte einlösen möchtest, denn den Differenzbetrag kannst Du über eine Zuzahlung begleichen.
    <br/>
    Für unsere Partnerprämien ist dies nicht möglich.`,
  },
  {
    question: 'Kann ich den Lieferstatus meiner Prämienbestellung verfolgen?',
    answer: 'Du kannst Deine Bestellung in Deinem Kundenkonto einsehen. Sobald Deine Prämie verschickt wurde, erhältst Du eine E-mail mit Versandbestätigung und Sendungsverfolgung.',
  },
];
