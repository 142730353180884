
import { defineComponent, reactive, PropType } from 'vue';
import { RouterLink } from 'vue-router';

import useBreakpoints from '@/use/useBreakpoints';

import Badge from '@/components/Badge/Badge.vue';
import Button from '@/components/Button/Button.vue';
import ResponsiveImage from '@/components/ResponsiveImage/ResponsiveImage.vue';
import Icon from '@/components/Icon/Icon.vue';

import { ResponsiveImageSet } from '@/types';

const iconMap = {
  playable: require('@/modules/academy/assets/svgs/challengeCategory/icons-16-challenge.svg'),
  podcast: require('@/modules/academy/assets/svgs/challengeCategory/icons-16-audio.svg'),
  video: require('@/modules/academy/assets/svgs/challengeCategory/icons-16-video.svg'),
};

const pointsTypeMap = {
  iqos_points: 'IQOS Points',
  statuspoints: 'Statuspunkte',
};

export default defineComponent({
  components: {
    Badge,
    Button,
    ResponsiveImage,
    Icon,
    RouterLink
  },
  props: {
    image: {
      type: Object as PropType<ResponsiveImageSet>,
      default: null,
    },
    points: {
      type: Number || String,
      default: null,
    },
    pointsType: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null,
    },
    ctaName: {
      type: String,
      default: null,
    },
    category: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    labelColor: {
      type: String,
      default: null,
    },
    link: {
      type: String,
      default: null,
    },
    isCampaignDisplay: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['teaserContentClick', 'ctaClick'],
  setup(_, { emit }) {
    const { isLg } = reactive(useBreakpoints());
    const isScreenLg = isLg();

    const onContentClick = () => {
      if(!isScreenLg) {
        emit('teaserContentClick');
      }
    };

    const onCtaClick = () => {
      emit('ctaClick');
    };

    return {
      iconMap,
      pointsTypeMap,
      isScreenLg,
      onContentClick,
      onCtaClick,
    };
  },
});
